import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import JwtService from '@/core/services/jwt.service'

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    Vue.use(VueAxios, axios)
    Vue.axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT
    console.log('BASE', process.env.VUE_APP_API_ENDPOINT)
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    Vue.axios.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${JwtService.getToken()}`
  },

  query(resource, params) {
    return Vue.axios.get(resource, params).catch((error) => {
      // console.log(error);
      throw new Error(`[VT] ApiService ${error}`)
    })
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = '') {
    let url = ''
    if (slug && (slug?.startsWith('?') || slug.includes('/ebitda'))) {
      url = `${resource}${slug}&client=1`
    } else if (slug) {
      url = `${resource}/${slug}?client=1`
    } else {
      url = `${resource}?client=1`
    }
    return Vue.axios.get(url).catch((error) => {
      throw new Error(`[VT] ApiService ${error}`)
    })
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return Vue.axios.post(`${resource}`, params).catch((error) => {
      throw new Error(`[VT] ApiService ${error}`)
    })
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    let url = ''
    if (slug && slug?.startsWith('?')) {
      url = `${resource}${slug}`
    } else if (slug) {
      url = `${resource}/${slug}`
    } else {
      url = resource
    }

    const config = {
      headers: {
        'content-type': 'application/merge-patch+json',
      },
    }

    return Vue.axios.patch(url, params, config).catch((error) => {
      throw new Error(`[VT] ApiService ${error}`)
    })
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, slug, params) {
    let url = ''
    if (slug && slug?.startsWith('?')) {
      url = `${resource}${slug}`
    } else if (slug) {
      url = `${resource}/${slug}`
    } else {
      url = resource
    }

    console.log('PUT', resource, slug)
    console.log(url)
    console.log(params)

    const config = {
      headers: {
        'content-type': 'application/merge-patch+json',
      },
    }

    return Vue.axios.patch(url, params, config).catch((error) => {
      throw new Error(`[VT] ApiService ${error}`)
    })
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    return Vue.axios.delete(resource).catch((error) => {
      // console.log(error);
      throw new Error(`[VT] ApiService ${error}`)
    })
  },
}

export default ApiService
