export const locale = {
  MAIN_MENU: {
    DASHBOARD: 'Nadzorna ploča',
    ADD: 'Dodaj',
    BACK_TO_LIST: 'Povratak na popis',
    COMPANY: 'Kompanija',
    COMPANIES: 'Kompanije',
    MY_COMPANIES: 'Moje kompanije',
    ALL_COMPANIES: 'Sve kompanije',
    DAILY_REVENUE: 'Dnevni prihod',
    ADD_NEW_COMPANY: 'Dodaj novu',
    USERS: 'Korisnici',
    ALL_USERS: 'Svi korisnici',
    ADD_NEW_USER: 'Dodaj novog korisnika',
    LOGGERS: 'Loggeri',
    ALL_LOGGERS: 'Svi loggeri',
    ADD_NEW_LOGGER: 'Dodaj novog',
    DEVICES: 'Uređaji',
    NO_RESULTS_FOUND: 'Nema rezultata',
    ALL_DEVICES: 'Svi uređaji',
    ADD_NEW_DEVICE: 'Dodaj novi uređaj',
    CRITICAL_HISTORY: 'Kritična povijest',
    SERVICE_MODE_HISTORY: 'Povijest servisnog načina',
    MAINTENANCE: 'Održavanje',
    MAINTENANCE_BY_DEVICE: 'Po uređaju',
    MAINTENANCE_IN_RANGE: 'Po rasponu',
    DEVICES_MAP: 'Karta uređaja',
    CITIES: 'Gradovi',
    ALL_CITIES: 'Svi gradovi',
    ADD_NEW_CITY: 'Dodaj novi',
    LESSORS: 'Najmodavci',
    LESSOR: 'Najmodavac',
    ALL_LESSORS: 'Svi najmodavci',
    ADD_NEW_LESSOR: 'Dodaj novog',
    LOCATIONS: 'Lokacije',
    ADD_LOCATION: 'Dodaj lokaciju',
    ALL_LOCATIONS: 'Sve lokacije',
    ADD_NEW_LOCATION: 'Dodaj novu',
    TAGS: 'Tagovi',
    ALL_TAGS: 'Svi tagovi',
    ADD_NEW_TAG: 'Dodaj novi',
    DEPOSITS: 'Depoziti',
    VIEW: 'Pogledaj',
    ADD_NEW_DEPOSITS: 'Dodaj nove',
    DELETE_DEPOSITS: 'Obriši',
    DETAILED_REVIEW: 'Detaljan pregled',
    TODAY_REVENUE: 'Današnji prihod',
    FINANCE_REPORT: 'Financijsko izvješće',
    FINANCE_REPORTS: 'Financijska analiza',
    TOKEN_REPORTS: 'Token analiza',
    LOGISTIC_REPORTS: 'Logistička analiza',
    FINANCE_REPORTS_BY_DEVICES: 'Po uređaju',
    FINANCE_REPORTS_BY_LOCATIONS: 'Po lokaciji',
    FINANCE_REPORTS_BY_LESSOR: 'Po najmodavcima',
    FINANCE_REPORTS_BY_COMPANY: 'Po kompaniji',
    COMPARATIVE_ANALYSIS: 'Usporedna analiza',
    COMPARATIVE_ANALYSIS_BY_DEVICES: 'Po uređaju',
    COMPARATIVE_ANALYSIS_BY_LOCATIONS: 'Po lokaciji',
    COMPARATIVE_ANALYSIS_BY_LESSOR: 'Po najmodavcima',
    TRANSACTIONS: 'Transakcije',
    BILLS: 'Računi',
    ALL_BILLS: 'Svi računi',
    CANCELLATION_BILL: 'Otkazivanje',
    NEW_BILL: 'Kreiraj novi',
    SETTINGS: 'Postavke',
    GENERATE: 'Generiraj',
    IMPORT: 'Uvozi',
    COMPANIES_AND_USERS: 'Kompanije i korisnici',
    LOYALTY: 'Vjernost',
    LOGGERS_AND_DEVICES: 'Loggeri i uređaji',
    CITIES_LESSORS_AND_TAGS: 'Gradovi, najmodavci i tagovi',
    REPORTS: 'Izvješća',
    TRAFIC: 'Promet',
    EXPORTS: 'Izvozi',
    EXPORT: 'Izvoz',
    TRANSACTIONS_BILLS_COMPARISON: 'Usporedba transakcija i računa',
  },
  PAGES: {
    DASHBOARD: 'Nadzorna ploča',
    DEVICES: 'Uređaji',
    CRITICAL_DEVICES: 'Kritični uređaji',
    LESSORS: 'Najmodavci',
    ALL_LESSORS: 'Svi najmodavci',
    UPDATE_LESSORS: 'Uredi najmodavca',
  },
  WIDGET: {
    SALES_STAT: 'Statistika prodaje',
    WEEKLY_SALES: 'Tjedna prodaja',
    NEW_USERS: 'Novi korisnici',
    ITEM_ORDERS: 'Narudžbe artikala',
    BUG_REPORTS: 'Izvješća o greškama',
    WEEKLY_INCOME: 'Tjedni prihod',
    GENERATE_REPORT: 'Generiraj izvješće',
  },
  GENERAL: {
    RESET: 'Resetiraj',
    AUTHORS: 'Autori',
    GROUPS: 'Grupe',
    TRENDS: 'Trendovi',
    ORDER: 'Narudžba',
    EVENT: 'Događaj',
    REPORT: 'Izvješće',
    POST: 'Objava',
    FILE: 'Datoteka',
    CHOOSE_LABEL: 'Odaberi oznaku',
    CUSTOMER: 'Kupac',
    PARTNER: 'Partner',
    SUPLIER: 'Dobavljač',
    MEMBER: 'Član',
    STAFF: 'Osoblje',
    CALLS: 'Pozivi',
    SETTINGS: 'Postavke',
    HELP: 'Pomoć',
    PRIVACY: 'Privatnost',
    VENDING_TYCOON_INFO:
      'Sustav Vending Tycoon trenutno se ažurira s v1.2 na potpuno novi v2.0. U sljedećih nekoliko dana mogući su kvarovi sustava. Hvala na razumijevanju.',
    YES: 'Da',
    NO: 'Ne',
    HI: 'Bok',
    SELECT_ALL: 'Odaberi sve',
    NONE: 'Nijedan',
    SHOW_ALL: 'Prikaži sve',
    ADD: 'Dodaj',
    CLOSE: 'Zatvori',
    DAILY_INDEX: 'Dnevni indeks',
    LAST_YEAR_DAILY_INDEX: 'Dnevni indeks prošle godine',
    WEEKLY_INDEX: 'Tjedni indeks',
    LAST_YEAR_WEEKLY_INDEX: 'Tjedni indeks prošle godine',
    MONTHLY_INDEX: 'Mjesečni indeks',
    LAST_YEAR_MONTHLY_INDEX: 'Mjesečni indeks prošle godine',
    YEARLY_INDEX: 'Godišnji indeks',
    DEVICES_TOTAL: 'Ukupno uređaja',
    PROBLEMATIC_TOTAL: 'Problematični uređaji',
    CRITICAL_TOTAL: 'Kritični uređaji',
    CURRENT_BALANCE: 'Trenutni saldo',
    CURRENT_MONTH_REVENUE: 'Prihod ovog mjeseca',
    LAST_MONTH_REVENUE: 'Prihod prošlog mjeseca',
    TRAFFIC_FOR_THE_LAST_30_DAYS: 'Promet za zadnjih 30 dana',
    CUMULATIVE: 'Kumulativno',
    CANCEL: 'Otkaži',
    REMOVE_ALL: 'Ukloni sve',
    COIN: 'Kovanica',
    ALL_COINS_IN_DEVICES: 'Sve kovanice u uređajima',
    ALL_TRANSACTIONS_IN_THIS_MONTH: 'Sve transakcije ovog mjeseca',
    ALL_TRANSACTIONS_IN_PREVIOUS_MONTH: 'Sve transakcije prošlog mjeseca',
    GENERATE_REPORT: 'Generiraj izvješće',
    GENERATE_EXPORTS: 'Generiraj izvoze',
    SHOW_DEVICES_CHART: 'Prikaži grafikon uređaja',
    PREDICT_TRAFFIC: 'Predvidi promet',
    USER_PROFILE: 'Korisnički profil',
    USER_PROFILE_DETAILS: 'Postavke računa i više',
    SIGN_OUT: 'Odjavi se',
    TRAFFIC_LAST_365_DAYS: 'Promet zadnjih 365 dana',
    ON: 'Uključeno',
    OFF: 'Isključeno',
    ACTIONS: 'Akcije',
    DATE: 'Datum',
    DATE_AND_TIME: 'Datum i vrijeme',
    TOTAL: 'Ukupno',
    LOCATION: 'Lokacija',
    LOCATIONS_NUMBER: 'Broj lokacija',
    AMOUNT: 'Iznos',
    REPORTED_AMOUNT: 'Prijavljeni iznos',
    PAYMENT_TYPE: 'Način plaćanja',
    NAME: 'Ime',
    DESCRIPTION: 'Opis',
    VAT: 'PDV',
    ADDRESS: 'Adresa',
    PHONE: 'Telefon',
    EMAIL: 'Email',
    WEEKS: 'Tjedni',
    MONTH: 'Mjesec',
    WEEK: 'Tjedan',
    DAY: 'Dan',
    PAID: 'Plaćeno',
    YEAR: 'Godina',
    SAVE: 'Spremi',
    SAVE_AND_EXIT: 'Spremi i izađi',
    SAVE_AND_ADD_NEW: 'Spremi i dodaj novo',
    OPTIONS: 'Opcije',
    PURCHASE: 'Kupnja',
    SALE: 'Prodaja',
    TIME: 'Vrijeme',
    SEARCH: 'Pretraži',
    NO_RECORD_FOUND: 'Nema zapisa',
    LOADING: 'Učitavanje',
    UPDATE: 'Ažuriraj',
    DEVICE: 'Uređaj',
    ENTER_DEVICE_NAME: 'Upiši ime uređaja',
    DEVICES_LOCATIONS: 'Lokacije uređaja',
    SEND: 'Pošalji',
    ACTION: 'Akcija',
    RESULTS: 'Rezultati',
    USER: 'Korisnik',
    LESSOR: 'Najmodavac',
    ADVANCED_VIEW: 'Napredan Prikaz',
    SIMPLE_VIEW: 'Jednostavan Prikaz',
    ROLE: 'Rola',
    TOKENS: 'Žetoni',
    TOKEN_DROP_DEVICE: 'Token·Drop·Uređaj',
    COLUMNS_SELECT: 'Odabir kolona',
    FINANCE_TOTAL: 'Financijski total',
    NETTO_COIN: 'Netto Coin',
    NETTO_SMS: 'Netto SMS',
    SMS: 'SMS',
    NETTO_CARD: 'Netto Card',
    CARD: 'Card',
    NETTO_CSC: 'Netto CSC',
    LOYALTY: 'Loyalty',
  },
  LESSORS: {
    SHOW_INFO: 'Prikaži detalje najmodavca',
    EDIT_LESSOR: 'Uredi najmodavca',
    DELETE_LESSOR: 'Ukloni najmodavca',
    CONTRACT_START_DATE: 'Početni datum ugovora',
    CONTRACT_END_DATE: 'Krajnji datum ugovora',
    RENTAL_TYPE: 'Tip najma',
    PAYMENT_INSURANCE: 'Osiguranje plaćanja',
    RENTAL_PERCENTAGE: 'Postotak najma',
    INCOME_LIMIT: 'Ograničenje prihoda',
    CONTRACT_RENTAL_AMOUNT: 'Iznos ugovorenog najma',
  },
  LOGISTIC_ANALYSIS: {
    DEVICE_FILTERS: 'Filteri uređaja',
    APPLY_FILTER: 'Odaberite jedan od filtera iznad.',
    NO_DATA: 'Za ovaj filter nema dostupnih uređaja.',
    SALDO_REDUCERS: 'Saldo Reducer uređaji',
    MONSTERS: 'Monster uređaji',
    PRIZE_DEVICES: 'Nagradni uređaji',
  },
  BUTTON: {
    ADD_COMPANY: 'Dodaj novu kompaniju',
  },
  DASHBOARD: {
    SHOW_GRAPHS: 'Prikaži grafove',
    ALIGN_WEEKENDS: 'Usklađivanje vikenda',
  },
  DEVICE_PERFORMANCE_GRAPH: {
    PERFORMING_DEVICE: 'uređaj',
    BEST: 'Najbolji',
    WORST: 'Najgori',
  },
  USERS: {
    EDIT_USER: 'Uredi korisnika',
    DELETE_USER: 'Ukloni korisnika',
  },
  LOCATIONS: {
    EDIT_LOCATION: 'Uredi lokaciju',
    DELETE_LOCATION: 'Ukloni lokaciju',
  },
  DEVICES: {
    JUNIOR_DEVICE_QUESTIONNAIRE: 'Upitnik o uređaju za juniore',
    SHOW_TAX_STICKER: 'Prikaži poreznu naljepnicu',
    SHOW_QR_CODE: 'Prikaži QR kod',
    EMPTY_DEVICE: 'Isprazni uređaj',
    EDIT_DEVICE: 'Izmijeni uređaj',
    DELETE_DEVICE: 'Ukloni uređaj',
    DEVICES_NUMBER: 'Broj uređaja',
    LAST_EMPTY: 'Zadnje pražnjenje',
  },
  FORMS: {
    REQUIRED_FIELDS: 'sva polja su obavezna',
  },
  PROMO_BOX: {
    EDIT_PROMO_BOX: 'Uredi Promo Box',
    ALL_PROMO_BOXES: 'Svi Promo Boxevi',
    UPDATE_PROMO_BOX: 'Ažuriraj Promo Box',
    ADD_NEW: 'Dodaj Novi',
    PROMO_BOXES: 'Promo Boxevi',
    LOYALTY_PROMO_BOXES: 'Promo Boxevi za Vjernost',
    TITLE: 'Naslov',
    BACKGROUND_COLOR: 'Boja Pozadine',
    TEXT_COLOR: 'Boja Teksta',
    ACTION_BUTTON: 'Akcijski Gumb',
    OPTIONS: 'Opcije',
    DELETE_CONFIRMATION_TITLE: 'Obrisano',
    DELETE_CONFIRMATION_TEXT: 'Promo Kutija je uspješno obrisana.',
    DELETE_ERROR_TITLE: 'Pogreška',
    DELETE_ERROR_TEXT: 'Došlo je do pogreške prilikom brisanja Promo Kutije.',
    LOADING_MESSAGE: 'Učitavanje Promo Boxeva...',
    ENTER_TITLE_PLACEHOLDER: 'Unesite naslov promo boxa',
    SELECT_BACKGROUND_COLOR: 'Odaberite Boju Pozadine',
    SELECT_TEXT_COLOR: 'Odaberite Boju Teksta',
    SELECT_ACTION_BUTTON: 'Odaberite Akcijski Gumb',
    INCLUDE_ACTION_BUTTON: 'Uključite Akcijski Gumb',
    YES: 'Da',
    NO: 'Ne',
    PROFILE: 'Profil',
    CREATE_SUCCESS: 'Promo Box je uspješno stvoren.',
    CREATE_ERROR: 'Došlo je do pogreške prilikom stvaranja Promo boxa.',
  },
  COMPANIES: {
    SHOW_USERS: 'Prikaži korisnike',
    SHOW_LOGGERS: 'Prikaži loggere',
    SHOW_DETAILED_VIEW: 'Detaljan prikaz uređaja',
    SHOW_TRANSACTIONS: 'Prikaži transakcije',
    NAME_AND_OIB: 'Ime i OIB',
    DATE_AND_TIME: 'Datum i vrijeme',
    TOKEN_TYPE: 'Token tip',
    TOKEN_AMOUNT: 'Količina',
    MODULE: 'Modul',
    MODULES: 'Moduli',
    MODULE_COMPONENTS: 'Komponente modula',
    DEVICE_AND_LESSOR: 'Uređaj i najmodavac',
    SERVICE_MODE: 'Servisni način',
    COMPANY_DAILY_REVENUE: 'Dnevni prihod kompanije',
    TRANSACTIONS_NUMBER: 'Broj transakcija',
    DEVICE_NAME: 'Ime uređaja',
    COMPANY_INFO: 'Informacije o kompaniji',
    COMPANY_INFO_DETAIL: 'Ime, Email, OIB, PDV',
    MODULES_AND_LOGGERS: 'Moduli i loggeri',
    MODULES_AND_LOGGERS_DETAIL: 'Odaberi aktivne module',
    USERS: 'Korisnici',
    USERS_DETAIL: 'Dodavanje korisnika',
    FINAL_CHECK: 'Završna provjera',
    FINAL_CHECK_DETAIL: 'Pregled i uređivanje',
    IN_VAT_SYSTEM: 'U sustavu PDV-a',
    SEND_EMAIL_NOTIFICATIONS: 'Slanje email obavijesti',
    NOTIFICATION_EMAILS: 'Emailovi za obavijesti',
    NOTIFICATION_EMAIL: 'Obavijesni email',
    DAILY_REPORTS: 'Dnevna izvješća',
    DEPOSIT_NOTIFICATIONS: 'Obavijesti o depozitima',
    DEVICE_EMPTY: 'Obavijesti o praznim uređajima',
    INDEX_CHARTS: 'Indeks grafikoni',
    DEVICES_REVENUE: 'Prihod uređaja zadnjih 30 dana',
    REPORTS_IN_RANGE: 'Izvješće po danima u rasponu',
    AR_DAYS_IN_WEEK: 'AR dani u tjednu',
    HOURS_OF_THE_DAY: 'Sati u danu',
    CHECK_INPUT: 'Provjeri unos i uređivanje kompanije',
    OPERATIONAL_COSTS: 'Operativni Troškovi',
    FORMS: {
      COMPANY_NAME: 'Unesi ime kompanije',
      SHORT_DESCRIPTION: 'Unesi kratki opis',
      OIB_NUMBER: 'Unesi OIB broj',
      COMPANY_ADDRESS: 'Unesi adresu kompanije',
      CONTACT_PHONE: 'Unesi kontakt telefon',
      EMAIL_ADDRESS: 'Unesi email adresu',
      SELECT_MODULE: 'Odaberi modul',
      SELECT_USERS: 'Odaberi korisnike',
      PREVIOUS_STEP: 'Prethodni korak',
      EDIT_COMPANY: 'Uredi kompaniju',
      NEXT_STEP: 'Sljedeći korak',
    },
    LOCATION_NAME: 'Naziv lokacije',
    LESSOR_NAME: 'Ime najmodavca',
  },
  ARTICLES: {
    ARTICLES: 'Artikli',
    ALL_ARTICLES: 'Svi artikli',
    UPDATE_ARTICLES: 'Ažuriraj artikl',
    ARTICLE_NAME: 'Ime artikla',
    NET_PURCHASE_PRICE: 'Nabavna neto cijena',
    NET_SELLING_PRICE: 'Prodajna neto cijena',
    GROSS_PURCHASE_PRICE: 'Nabavna bruto cijena',
    GROSS_SALES_PRICE: 'Prodajna bruto cijena',
    UNIT: 'Jedinica',
    SUPPLIER: 'Dobavljač',
    NET_PRICE: 'Neto cijena',
    GROSS_PRICE: 'Bruto cijena',
    REPOSITORIES: 'Skladišta',
    EDIT_REPOSITORY: 'Uredi skladište',
    ENTER_REPOSITORY_NAME: 'Unesi ime skladišta',
    ENTER_LESSOR_NAME: 'Unesi ime najmodavca',
    QUANTITY: 'Količina',
    ADD_ARTICLE: 'Dodaj artikl',
    SELECT_ARTICLE: 'Odaberi artikl',
  },
  LOCATION_MANAGEMENT: {
    CITY: 'Grad',
    MAP: 'Karta',
    REMOVE: 'Obriši',
    MONDAY: 'Ponedjeljak',
    TUESDAY: 'Utorak',
    WEDNESDAY: 'Srijeda',
    THURSDAY: 'Četvrtak',
    FRIDAY: 'Petak',
    SATURDAY: 'Subota',
    SUNDAY: 'Nedjelja',
    START_TIME: 'Vrijeme otvaranja',
    END_TIME: 'Vrijeme zatvaranja',
    LESSOR: 'Najmodavac',
    LOCATION_NAME: 'Ime lokacije',
    LOCATION_ACTIVE: 'Status lokacije',
    EDIT_LOCATION: 'Uredi lokaciju',
    DESCRIPTION: 'Opis',
    LESSOR_RENT: 'Najam najmodavca',
    RENTAL_TYPE: 'Tip najma',
    CONTRACT_RENTAL_AMOUNT: 'Iznos ugovornog najma',
    INCOME_LIMIT: 'Income limit',
    RENTAL_PERCENTAGE: 'Rental Percentage',
    RESPONSIBLE_WORKER: 'Zadužena osoba',
    NEW_RESPONSIBLE_WORKER: 'Nova Zadužena osoba',
    WORKING_TIME: 'Radno vrijeme',
    ADDRESS: 'Adresa',
    STREET_NAME: 'Ime ulice',
    FISCALISATION: 'Fiskalizacija',
    CHOOSE_PAYMENT_METHOD: 'Odaberite metodu plaćanja',
  },
  DROPDOWN: {
    MY_CART: 'Moja Košarica',
    ITEMS: 'Stavke',
    FOR: 'za',
    SUB_TOTAL: 'Međuzbroj',
    PLACE_ORDER: 'Naruči',
    QUICK_ACTIONS: 'Brze Radnje',
    TASKS_PENDING: 'zadaci na čekanju',
  },
  QUICK_PANEL: {
    AUDIT_LOGS: 'Dnevnici Revizije',
    NOTIFICATIONS: 'Obavijesti',
    SETTINGS: 'Postavke',
    SYSTEM_MESSAGES: 'Sustavne Poruke',
    CUSTOMER_CARE: 'Služba za Korisnike',
    ENABLE_NOTIFICATIONS: 'Omogući Obavijesti',
    ENABLE_CASE_TRACKING: 'Omogući Praćenje Slučajeva',
    SUPPORT_PORTAL: 'Portal za Podršku',
    ENABLE_REPORT_EXPORT: 'Omogući Izvoz Izvještaja',
    ALLOW_DATA_COLLECTION: 'Dozvoli Prikupljanje Podataka',
    REPORTS: 'Izvještaji',
    GENERATE_REPORTS: 'Generiraj Izvještaje',
    MEMBERS: 'Članovi',
    ENABLE_MEMBERS_SIGNUP: 'Omogući Registraciju Članova',
    ALLOW_USER_FEEDBACKS: 'Dozvoli Povratne Informacije Korisnika',
    ENABLE_CUSTOMER_PORTAL: 'Omogući Portal za Korisnike',
  },
  DROPDOWN_NOTIFICATION: {
    UNFISCALIZED_BILLS: 'Nefiskalizirani računi',
    UNFISCALIZED: 'Nefiskalizirani',
    LAST_TEN: 'Zadnjih 10',
    ALL_FISCALIZED: 'Svi računi su fiskalizirani...',
    CAUGHT_UP: 'Sve je u redu!',
    NO_NOTIFICATIONS: 'Nema novih obavijesti.',
  },
  BILLS: {
    FILTER_BILLS: 'Filtriraj račune',
    ENTER_BUSINESS_LABEL: 'Unesi poslovnu oznaku',
    ENTER_DEVICE_IMEI: 'Unesi IMEI uređaja',
    ENTER_BUSINESS_LABEL_OR_DEVICE_IMEI: 'Unesi poslovnu oznaku ili IMEI',
    BILL_INFO: 'Unesi podatke o računu',
    BILL_NUMBER: 'Broj računa',
    BUSINESS_LABEL: 'Poslovna oznaka',
    ISU_NUMBER: 'ISU broj',
    ADD_BILL: 'Dodaj račun',
    REQUEST_BILL: 'Zahtjevaj račun',
    SELECT_DEVICES: 'Odaberi uređaje',
    CREATE_TRANSACTION: 'Kreiraj transakciju',
    UNFISCALIZED_BILLS: 'Nefiskalizirani računi',
    FISCAL: 'Fiskalni',
  },
  CITIES: {
    ALL_CITIES: 'Svi gradovi',
    UPDATE_CITY: 'Ažuriraj grad',
    CITIES: 'Gradovi',
    ADD_NEW_CITY: 'Dodaj novi grad',
    ADD_CITY: 'Dodaj grad',
    CITY_NAME: 'Ime grada',
    LOCATIONS_NUMBER: 'Broj lokacija',
  },
  COMPARATIVE_ANALYSIS: {
    DEVICE_A_REPORT: 'Izvješće uređaja A',
    DEVICE_B_REPORT: 'Izvješće uređaja B',
    LESSORS_A_REPORT: 'Izvješće najmodavaca A',
    LESSORS_B_REPORT: 'Izvješće najmodavaca B',
    LOCATION_A_REPORT: 'Izvješće lokacije A',
    LOCATION_B_REPORT: 'Izvješće lokacije B',
    AVERAGE_REPORT_BY_DAYS: 'Prosječno izvješće po danima u tjednu',
    DEVICES_SELECTION: 'Odabir uređaja',
  },
  DEPOSITS: {
    REPORT_DEPOSIT_AMOUNT: 'Prijavi iznos depozita',
    REPORT_SUCCESS: 'Izvješće uspješno kreirano.',
    VERIFICATION_SUCCESS: 'Verifikacija uspješna.',
    LOCATION_SEARCH: 'Pretraži lokaciju',
    PENDING_VERIFICATION: 'Na čekanju za verifikaciju',
    VERIFY_SELECTED: 'Verificiraj odabrano',
    DEVICE_NAME: 'Naziv uređaja',
    DEPOSIT: 'Polog',
    DEPOSITS: 'pologa',
    REPORTED: 'Prijavljeno',
    DIFFERENCE: 'Razlika',
    NO_DATA: 'Nema podataka za odabrano vremensko razdoblje',
    REPORTED_AMOUNT: 'Prijavljeni Iznos',
    REPORT_AMOUNT: 'Prijavi Iznos',
    VERIFY_DEPOSIT: 'Verificiraj Uplatu',
    VERIFY_CONFIRM: 'Jeste li sigurni da želite verificirati ovaj polog?',
    VERIFY_MULTIPLE_CONFIRM: 'Jeste li sigurni da želite verificirati',
    VERIFY_ALL: 'Verificiraj sve',
    TOKEN_DEPOSIT: 'Polog žetona',
    TOKEN_REPORTED: 'Broj prijavljenih žetona',
    TOKEN_DIFFERENCE: 'Razlika žetona'
  },
  DISCHARGE: {
    DISCHARGE_TIME: 'Vrijeme pražnjenja',
    DISCHARGE_HISTORY: 'Povijest pražnjenja',
  }
}
