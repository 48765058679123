import Vue from 'vue'
import '@babel/polyfill'
import VueHtml2Canvas from 'vue-html2canvas'
import VueGates from 'vue-gates'

import App from './App.vue'
import router from './router'
import store from '@/core/services/store'
import '@/core/filters/filters'
import ApiService from '@/core/services/api.service'
import {VERIFY_AUTH} from '@/core/services/store/auth.module'
import {RESET_LAYOUT_CONFIG} from '@/core/services/store/config.module'
import {ValidationObserver, ValidationProvider} from 'vee-validate'

Vue.config.productionTip = true
Vue.use(VueHtml2Canvas)
Vue.use(VueGates, {
    superRole: 'admin',
    persistent: true
})

import 'popper.js'
import 'tooltip.js'
import PerfectScrollbar from 'perfect-scrollbar'

window.PerfectScrollbar = PerfectScrollbar
import ClipboardJS from 'clipboard'

window.ClipboardJS = ClipboardJS
import Notifications from 'vue-notification'
import i18n from '@/core/plugins/vue-i18n'

// Vue 3rd party plugins
import '@/core/plugins/bootstrap-vue'
import '@/core/plugins/perfect-scrollbar'
import '@/core/plugins/inline-svg'
import '@/core/plugins/apexcharts'
import '@/core/plugins/metronic'
import 'leaflet/dist/leaflet.css'

// API service init
ApiService.init()

// Notifications
Vue.use(Notifications)

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
import {extend} from 'vee-validate'
import {required, email} from 'vee-validate/dist/rules'

extend('required', required)
extend('email', email)

// version
const package_json = require('../package.json')
console.log(package_json.version)

router.beforeEach((to, from, next) => {
    if (from.name === 'deposits-new') {
        let confirmation = confirm('Are you sure you want...')
        if (confirmation === false) return
    }

    console.log(router.app.$gates.getRoles())
    console.log(to)
    console.log(to.gates)


    Promise.all([store.dispatch(VERIFY_AUTH)]).then(next)

    store.dispatch(RESET_LAYOUT_CONFIG)

    setTimeout(() => {
        window.scrollTo(0, 0)
    }, 100)
})

new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
}).$mount('#app')
